export const routes = {
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  dashboard: '/app',
  programs: '/app/programs',
  exercises: '/app/exercises',
  equipments: '/app/equipments',
  members: '/app/members'
}


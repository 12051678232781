import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'views/components/header'
import Sidebar from 'views/components/sidebar'

const PrivateLayout = () => {
	return (
		<>
			<Suspense>
				<section className="slider-bar-section">
					<div className="container-fluid">
						<div className="row">

							<nav id="sidebar" className="sidebar-colums">
								<Sidebar />
							</nav>

							<main className="main-content-right">
								<Suspense>
									<Header />
									<Outlet />
								</Suspense>
							</main>

						</div>
					</div>
				</section>
			</Suspense>
		</>
	)
}
export default PrivateLayout
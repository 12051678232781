import React from 'react';
import avatarimg from './images/avatar-table.png'

import './style.scss'
import { Button, SearchField } from 'views/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const Members = () => {
  return (

    <div className="member-card-row">
      <div className="row">
        <form>
          <SearchField />
        </form>
        <div className="space-between">
          <div className="member-card-heading">
            <h2>Team Members</h2>
            <p>124 results</p>
          </div>

          <div className="new-member-button">
            <Button
              className="primary text-orofg"
              name="New Team Member"
            />
          </div>
        </div>

        <div className="member-leaderboard-card card">
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Signed up</th>
                <th className="text-center">Role</th>
                <th className="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>

                  <div className="align-middles">
                    <div className="flex-shrink-0">
                      <img src={avatarimg} alt="avatar" />
                    </div>

                    <div className="flex-grow-1 ms-3">
                      <h6>Jane Cooper</h6>
                      <p> jane.cooper@example.com</p>
                    </div>
                  </div>
                </td>
                <td>23/05/2021</td>
                <td className="text-center">Opretar</td>
                <td className="text-center"><a href="#"><FontAwesomeIcon icon={faEllipsisVertical} /></a></td>


              </tr>

              <tr>
                <td>

                  <div className="align-middles">
                    <div className="flex-shrink-0">
                      <img src={avatarimg} alt="avatar" />
                    </div>

                    <div className="flex-grow-1 ms-3">
                      <h6>Jane Cooper</h6>
                      <p> jane.cooper@example.com</p>
                    </div>
                  </div>
                </td>
                <td>23/05/2021</td>
                <td className="text-center">7</td>
                <td className="text-center"><a href="#"><FontAwesomeIcon icon={faEllipsisVertical} /></a></td>

              </tr>

            </tbody>
          </table>
          <div className="card-footer border-0">
            <div className="row">
              <div className="col-6">
                <p>Showing 1 to 5 of 70 results</p>
              </div>
              <div className="col-6 text-end">
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item">
                      <a className="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>
  )
}

export default Members
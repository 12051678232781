import { routes } from 'utils';
import { Equipments, Exercises, Home, Members, ProgramDetails, Programs } from 'views/pages';

const PrivateRoutes = [
  {path: `${routes.dashboard}`, exact: true, name: 'home', component: Home},
  {path: `${routes.equipments}`, exact: true, name: 'equipments', component: Equipments},
  {path: `${routes.programs}`, exact: true, name: 'programs', component: Programs},
  {path: `${routes.programs}/:id`, exact: true, name: 'program-details', component: ProgramDetails},
  {path: `${routes.exercises}`, exact: true, name: 'exercises', component: Exercises},
  {path: `${routes.members}`, exact: true, name: 'members', component: Members}
]

export default PrivateRoutes;
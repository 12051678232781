import { routes } from './routes'
import { strings } from './strings'

export { routes, strings }

export const generateQueryParams = (args: any) => {
  let str = ''
  let replace = ''
  for (const key in args) {
    if (typeof (args[key]) === 'object') {
      for (const key1 in args[key]) {
        str += `&filters[${key1}]=${args[key][key1]}`
        replace = str.slice(1)
      }
    } else {
      str += `&${key}=${args[key]}`
      replace = str.slice(1)
    }
  }
  return replace
}

export const capitalizeFirstLetter = (name = '') => {
  const firstLetter = name?.charAt(0).toUpperCase()
  const restOfName = name?.slice(1).toLowerCase()
  const capitalizedUserName = firstLetter + restOfName
  return capitalizedUserName;
};

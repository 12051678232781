// views/components/sidebar.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowRightFromBracket, faBell, faUser } from '@fortawesome/free-solid-svg-icons';

import './style.scss'

const Header = () => {

	return (
		<>
			<header className="main-header">
				<nav className="navbar">
					<ul className="navbar-nav me-auto">
						<li className="nav-item">
							
								<p><a href="#"><span><FontAwesomeIcon icon={faAngleLeft} /></span>Back</a></p>
							
						</li>
					</ul>

					<div className="user-list">
						<ul className="list-inline">

							<li className="list-inline-item">
								<a href="#" className="navbar-icon-botton">
									<FontAwesomeIcon icon={faBell} />
								</a>
							</li>

							<li className="list-inline-item">

								<div className="dropdown user-profile">
									<a className="btn avtar-user" href="#" role="button" data-bs-toggle="dropdown">
										<span className="">fg</span>
									</a>

									<ul className="dropdown-menu">

										<li>
											<a className="dropdown-item" href="#"><FontAwesomeIcon icon={faUser} />	&nbsp;User</a>
										</li>

										<li>
											<a className="dropdown-item" href="#"><FontAwesomeIcon icon={faUser} />&nbsp;Profile</a>
										</li>

										<li><a className="dropdown-item" href="#"><FontAwesomeIcon icon={faUser} /> &nbsp;Profile</a></li>
										<hr className="my-0" />
										<li><button className="dropdown-item"><FontAwesomeIcon icon={faArrowRightFromBracket} /> &nbsp;Logout</button></li>

									</ul>
								</div>
							</li>
						</ul>
					</div>
				</nav>
			</header >
		</>
	);
};

export default Header;

import store from 'store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services';

const initialState = {
  isUserLoggedIn: store.get(`${process.env.REACT_APP_ACCESS_TOKEN_KEY}`) ? true : false,
  me: {},
  globalSettings: {},
  printerDevice: ''
}

export const setLoggedInUser = createAsyncThunk('setLoggedInUser', async () => {
  return await authService.getMe()
})

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setAuth: (state, action) => {
      store.set(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9', action.payload)
      state.isUserLoggedIn = true
    },
    setPrintDevice: (state, action) => {
      state.printerDevice = action.payload
    },
    setDestroyAuth: (state) => {
      store.clearAll()
      state.isUserLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLoggedInUser.fulfilled, (state, action) => {
        state.me = action.payload
      })
  },
});

export const { setAuth, setDestroyAuth, setPrintDevice } = sessionSlice.actions;

export default sessionSlice.reducer;


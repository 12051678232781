import React from 'react';

import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import usericon from './icon/user.svg'
import workouticon from './icon/workout-icon.svg'
import exercisesicon from './icon/success-icon.svg'
import fullbody from './images/full-body-img.png'
import iframimg from './images/ifram-img.png'
import avatarimg from './images/avatar-table.png'






import './style.scss'


const Home = () => {
  return (
    <div className="dashboard-row">
      <div className="row">

        <div className="col-4">
          <div className="registered-card">
            <div className="card">

              <div className="align-middles">
                <div className="flex-shrink-0">
                  <img src={usericon} alt="user" />
                </div>
                <div className="flex-grow-1">
                  <p>Registered Users</p>
                  <h2>897<span><FontAwesomeIcon icon={faArrowUp} />&nbsp;21 (Last 7 days)</span></h2>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="col-4">
          <div className="registered-card">
            <div className="card">

              <div className="align-middles">
                <div className="flex-shrink-0">
                  <img src={workouticon} alt="workout-icon" />
                </div>
                <div className="flex-grow-1">
                  <p>Workouts Completed</p>
                  <h2>2,124<span className="workouts-days"><FontAwesomeIcon icon={faArrowUp} />&nbsp;36 (Last 7 days) </span></h2>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="col-4">
          <div className="registered-card">
            <div className="card">

              <div className="align-middles">
                <div className="flex-shrink-0">
                  <img src={exercisesicon} alt="exercises" />
                </div>
                <div className="flex-grow-1">
                  <p>Exercises Logged</p>
                  <h2>10,926<span className="workouts-days"><FontAwesomeIcon icon={faArrowUp} />&nbsp;58 (Last 7 days) </span></h2>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="most-populas-card">
          <div className="card">
            <h3>Most Popular <span>(Last 7 days)</span></h3>
            <div className="row">
              <div className="col-6">
                <div className="fullbody-col">
                  <h6>Workouts</h6>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="fullbody-col">
                  <h6>Equipment</h6>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>

                  <div className="full-body-card">
                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={fullbody} alt="fullbody-img" />
                      </div>
                      <div className="flex-grow-1">
                        <h2>Full Body</h2>
                        <p> 20 Completed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="member-sign-up">
          <div className="card">
            <div className="membersing-up-inner">
              <div className="member-sign-heading">
                <h3>Member Sign-up</h3>
              </div>
              <div className="monthly-tabs">
                <ul className="nav nav-pills" id="MemberSign" role="tablist">

                  <li className="nav-item">
                    <button className="nav-link active" id="pills-Monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-Monthly" type="button">Monthly</button>
                  </li>

                  <li className="nav-item">
                    <button className="nav-link" id="Weekly-tab" data-bs-toggle="pill" data-bs-target="#Weekly" type="button" >Weekly</button>
                  </li>

                  <li className="nav-item">
                    <button className="nav-link" id="pills-Daily-tab" data-bs-toggle="pill" data-bs-target="#pills-Daily" type="button">Daily</button>
                  </li>

                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-Monthly">
                    <img src={iframimg} alt="ifarm" className="img-fluid ifarm-img" />
                  </div>

                  <div className="tab-pane fade" id="Weekly">
                    <img src={iframimg} alt="ifarm" className="img-fluid ifarm-img" />

                  </div>
                  <div className="tab-pane fade" id="pills-Daily">
                    <img src={iframimg} alt="ifarm" className="img-fluid ifarm-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="member-leaderboard-card">
            <table className="table table-fixed">
              <thead>
                <tr>
                  <th><h3>Member Leaderboard<span> (Last 7 days)</span></h3></th>
                  <th>Last Session</th>
                  <th className="text-center">Completed Workouts</th>
                  <th className="text-center">Exercises Logged</th>
                  <th className="text-center">Badges Earned</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>

                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={avatarimg} alt="avatar" />
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h6>Jane Cooper</h6>
                        <p> jane.cooper@example.com</p>
                      </div>
                    </div>
                  </td>
                  <td>23/05/2021</td>
                  <td className="text-center">7</td>
                  <td className="text-center">49</td>
                  <td className="text-center">6</td>

                </tr>

                <tr>
                  <td>

                    <div className="align-middles">
                      <div className="flex-shrink-0">
                        <img src={avatarimg} alt="avatar" />
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <h6>Jane Cooper</h6>
                        <p> jane.cooper@example.com</p>
                      </div>
                    </div>
                  </td>
                  <td>23/05/2021</td>
                  <td className="text-center">7</td>
                  <td className="text-center">49</td>
                  <td className="text-center">6</td>

                </tr>

              </tbody>
            </table>
        </div>

      </div>
    </div>
  )
}

export default Home
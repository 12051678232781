// views/components/sidebar.jsx
import React from 'react';

import brandlogo from './images/main-logo.svg'

import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faCalendar, faCodeCompare, faFolder, faHouse, faSatelliteDish, faScrewdriver } from '@fortawesome/free-solid-svg-icons';



const Sidebar = () => {

	return (

		<div className="sidebar-colums">

			<div className="main-logo">

			<a href="#">
					<img src={brandlogo} alt="logo" className="img-fluid" width={124} height={24} />
				</a>
				
			</div>

			<div className="mb-auto">

				<ul className="list-unstyled">

					<li>
						<a href="#" className="nav-link">
							<span ><FontAwesomeIcon icon={faHouse} /></span>
							Dashboard
						</a>
					</li>

					<li>
						<a href="#" className="nav-link">
							<span> <FontAwesomeIcon icon={faScrewdriver} /></span>
							Equipment
						</a>
					</li>

					<li>
						<a href="#" className="nav-link">
							<span><FontAwesomeIcon icon={faFolder} /></span>
							Team
						</a>
					</li>

					<li>
						<a href="#" className="nav-link">
							<span><FontAwesomeIcon icon={faCalendar} /></span>
							Members
						</a>
					</li>

					<li>
						<a href="#" className="nav-link active">
							<span><FontAwesomeIcon icon={faCodeCompare} /></span>
							Programming
						</a>
					</li>

					<li>
						<a href="#" className="nav-link">
							<span><FontAwesomeIcon icon={faSatelliteDish} /></span>
							Index
						</a>
					</li>
				</ul>
			</div>

			<div className="logout-col">
				<button type="button" className="btn logout-button"><span><FontAwesomeIcon icon={faArrowRightFromBracket} /></span>Logout</button>
			</div>
		</div>

	);
};

export default Sidebar;

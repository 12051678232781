import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'

import { routes } from 'utils'
import PublicRoutes from 'routes/public'
import PrivateRoutes from 'routes/private'
import PublicLayout from 'views/layouts/public'
import PrivateLayout from 'views/layouts/private'
import { _Object } from 'utils/interfaces'

const App = () => {
  // const { isUserLoggedIn } = useSelector((state: { [key: string]: SessionState }) => state.session)

  return (
    <>
      <BrowserRouter>
        <Suspense>
          <Routes>
            <Route path="/" element={<PrivateLayout />} >
              <Route index element={<Navigate to={`${routes.dashboard}`} />} />

              {PrivateRoutes.map((route: _Object, i: number) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              })}
              <Route path="/*" element={<Navigate to={`${routes.dashboard}`} />} />

            </Route>

            <Route path="/" element={<PublicLayout />}>
              <Route index element={<Navigate to={`${routes.login}`} />} />

              {PublicRoutes.map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              })}

              <Route path="/*" element={<Navigate to={`${routes.login}`} />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  )
}
export default App


import React from 'react';

const ProgramDetails = () => {
  return (
    <div>
      <h1>ProgramDetails</h1>
    </div>
  )
}

export default ProgramDetails
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { _Object } from 'utils/interfaces'

const SearchField = ({ name, type = 'text', disabled, icon, loading, onChange, value, label, required = false, className = '', error, ...props }: _Object) => {
  return (
    <div className={`form-group search-input ${className}`}>

      <div className="input-group">
        <button type="submit" disabled={disabled} className={`input-group-text ${loading ? 'btn-loading' : ''}`}>
          {icon ? <i className={icon}></i> : <FontAwesomeIcon icon={faMagnifyingGlass} />}
        </button>

        {label && <label className="label-form">{label} {required && <span className="text-danger"></span>}</label>}

        <input
          type={type}
          autoComplete={'off'}
          name={name}
          onChange={onChange}
          value={value}
          // autoComplete="off"
          placeholder="Keyword"
          className="form-control"
          {...props} />

      </div>

      {error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
    </div>
  )
}

export default SearchField


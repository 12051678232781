import React from 'react';

const Exercises = () => {
  return (
    <div>
      <h1>Exercises</h1>
    </div>
  )
}

export default Exercises
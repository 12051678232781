import CommonService from './common.service';

class AuthService extends CommonService {

  async login(params: { [key: string]: string }) {
    return await this.post('login', params);
  }

  async forgotPassword(params: { [key: string]: string }) {
    return await this.post('reset-password-email', params)
  }

  async updatePassword(params: { [key: string]: string | null }) {
    return await this.post('update-password', params)
  }

  async getMe() {
    return await this.get('me',)
  }
}

export const authService = new AuthService();


import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { routes } from 'utils'
import { _Object } from 'utils/interfaces'
import { authService } from 'services'
import { setAuth } from 'redux/slices/session.slice'
import './login.scss'

import { Button, InputField, LinkButton } from 'views/components'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      email: Yup.string().label('Email').required().email(),
      password: Yup.string().label('Password').required(),
    }),

    onSubmit: async (values) => {
      setLoading(true)
      authService.login(values)
        .then((data: _Object) => {
          setLoading(false)
          if (data?.error === false) {
            localStorage.removeItem('cartLineItems')
            dispatch(setAuth(data.access_token))
            navigate(`${routes.dashboard}`)
          }
        })
    },
  })

  return (
    <>
      <div className="col-8 mx-auto vh-100 pos-justify pos-align pos-center flex-column login login-form-vh">
        <div className="card border-0 position-relative">
          <div className="text-center">
            <img src="/assets/images/logo.svg" alt="" className="img-fluid w-50 mb-4" />
            <h4>Welcome back</h4>
            <p>Sign in to your account</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              type="email"
              name="email"
              label="Email"
              autoFocus
              required={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched.email && formik.errors.email}
            />

            <InputField
              className="position-relative"
              type="password"
              name="password"
              label="Password"
              required={true}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik?.touched.password && formik.errors.password}
            />

            <div className="pos-justify pos-between">
              <Button
                type="submit"
                name="Sign In"
                className="primary"
                loading={loading}
              />
              <LinkButton
                label={'Forgot Password?'}
                path={`${routes.forgotPassword}`}
                className="link px-0"
              />
            </div>
          </form>

          <div className="text-center mt-4 pt-4">
            <p className="mb-0">
              By continuing, you agreed to&nbsp;<LinkButton path="#" className="link text-decoration-underline d-inline-block p-0 fw-normal align-baseline" label="Terms of use" />
              &nbsp;& <LinkButton path="#" className="link text-decoration-underline d-inline-block p-0 fw-normal align-baseline" label="Privacy Policy" />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login